<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">審査情報一覧</p>
        <hr class="title">
      </div>
      <div class="mt-4">
        <div class="search-area">
          <div class="flex flexCenter">
            <div class="search-middle inlineBlock search-space">
              <div>年度</div>
              <div>
                <b-form-select
                  :value="search.year"
                  @input="resetFilter(apptype=true, stage=true, bunri=true);setFilter('year', $event)"
                  :options="yearList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
                </b-form-select>
              </div>
            </div>

            <div class="search-middle-long inlineBlock search-space">
              <div>プログラム名</div>
              <div>
                <b-form-select
                  :value="search.apptype"
                  @input="resetFilter(apptype=false, stage=true, bunri=true);setFilter('apptype', $event);setBunri($event);fetchAppList()"
                  :options.sync="apptypeList">
                  <template v-slot:first>
                    <option :value="null">-- 未選択 --</option>
                  </template>
                </b-form-select>
              </div>
            </div>

            <div class="search-middle-long inlineBlock search-space">
              <div>審査段階</div>
              <div>
                <b-form-select
                  @input="resetFilter(apptype=false, stage=false, bunri=false);
                    setFilter('stageId', $event);fetchAppList()"
                  :value="search.stageId"
                  :options.sync="stageList">
                  <template v-slot:first>
                    <option :value="null">
                      {{defaultStageText}}
                    </option>
                  </template>
                </b-form-select>
              </div>
            </div>

            <div class="searchMiddle inlineBlock search-space">
              <div>文理区分</div>
              <div>
                <b-form-select
                  :value="search.bunri"
                  @input="setFilter('bunri', $event);fetchAppList()"
                  :options="bunriOption">
                  <template v-slot:first>
                    <option :value="''">-- 未選択 --</option>
                    <!-- <option :value="null">区分なし</option> -->
                  </template>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-4 mb-3 flex flexCenter flexColumn">
        <p class="mb-1">提出が完了した審査は、グレースケールで表示され、合計点と平均点の計算対象になります。</p>
      </div>


      <div class="contentsWidth mt-3">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="evalTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div
          v-for="(index) in vewFilterIndexList" :key="`app_${index}`"
          class="contentsWidth app-area pb-3 mb-4">

          <!-- 一段目 -->
          <div>
            <div class="view-short inlineBlock mr-2px">
              <div class="field-name flex-tate pl-3">受付番号</div>
              <div class="field-value flex-tate pl-3">{{appList[index].code}}</div>
            </div>
            <!-- 申請者氏名 期間と金額がない場合は長くする-->
            <div
              :class="nameClass">
              <div class="field-name flex-tate pl-3">{{usecaseLabel('NAME')}}</div>
              <div class="field-value flex-tate pl-3">
                {{(name(appList[index].usecase[constUsecase.NAME]))}}
              </div>
            </div>
            <div
              v-if="viewUsecase[constUsecase.TERM]"
              class="view-middle mr-2px inlineBlock">
              <div class="field-name flex-tate pl-3">{{usecaseLabel('TERM')}}</div>
              <div class="field-value flex-tate pl-3">
                {{appList[index].usecase[constUsecase.TERM][0].field_answer_text}}
                ~
                {{appList[index].usecase[constUsecase.TERM][1].field_answer_text}}
              </div>
            </div>
            <div
              v-if="viewUsecase[constUsecase.MONEY]"
              class="view-short inlineBlock">
              <div class="field-name flex-tate pl-3">{{usecaseLabel('MONEY')}}</div>
              <div class="field-value flex-tate pl-3">
                {{appList[index].usecase[constUsecase.MONEY][0].field_answer_text}}千円
              </div>
            </div>
          </div>
          <!-- 二段目 -->
          <div
            v-if="isShowApplyOrg && viewUsecase[constUsecase.APPLYORG]"
            class="contentsWidth">
            <div class="field-name flex-tate pl-3">申請団体名</div>
            <div class="field-value flex-tate pl-3">
              {{appList[index].usecase[constUsecase.APPLYORG][0].field_answer_text}}
            </div>
          </div>
          <div
            v-if="!isShowApplyOrg && viewUsecase[constUsecase.BELONGS]"
            class="contentsWidth">
            <div class="field-name flex-tate pl-3">申請者所属</div>
            <div class="field-value flex-tate pl-3">
              {{appList[index].usecase[constUsecase.BELONGS][0].field_answer_text}}
            </div>
          </div>
          <div
            v-if="viewUsecase[constUsecase.TITLE]">
            <div class="contentsWidth">
              <div class="field-name flex-tate pl-3">{{usecaseLabel('TITLE')}}</div>
              <div class="field-value flex-tate pl-3">
                {{appList[index].usecase[constUsecase.TITLE][0].field_answer_text}}
              </div>
            </div>
          </div>
          <!-- 三段目 -->
          <div
            v-if="viewUsecase[constUsecase.DETAIL]">
            <div class="contentsWidth">
              <div class="field-name flex-tate pl-3">{{usecaseLabel('DETAIL')}}</div>
              <div class="field-value flex-tate pl-3">
                {{appList[index].usecase[constUsecase.DETAIL][0].field_answer_text}}
              </div>
            </div>
          </div>
          <!-- 四段目-->
          <div>
            <div class="contentsWidth">
              <div class="field-name flex-tate pl-3">審査</div>
            </div>
            <div
              v-if="items[constEval.SCORE] && sumScores[index]"
              class="flex mx-2 mt-2">
              平均点: {{sumScores[index].avg}} 点
            </div>
            <!-- 9個ごとの評価項目の配列でループ -->
            <div
              v-for="(viewScoreItemList, listIndex) in viewScoreItemLists" :key="`list_${listIndex}`"
              class="my-2">
              <!-- 一段目 -->
              <div class="flex line-head mx-2 line-min">
                <div class="score-head score-name">
                  審査委員氏名
                </div>
                <div
                  v-for="(item, itemIndex) in viewScoreItemList" :key="`item_${itemIndex}`"
                  class="score-head score-item">
                  {{item.name}}
                </div>
                <div
                  v-for="(item, itemIndex) in items[constEval.ADJUSTMENT]" :key="`item_adujudsment_${itemIndex}`"
                  class="score-head score-item">
                  {{item.name}}
                </div>
                <!-- 最終列は合計点表示 -->
                <div
                  v-if="items[constEval.SCORE] && listIndex === viewScoreItemLists.length-1"
                  class="score-head score-item">
                  合計点
                </div>
              </div>
              <!-- 二段目以降は審査委員でループ -->
              <div
                v-for="(assign, assignIndex) in assigns[appList[index].bunri]" :key="`assign_${assignIndex}`"
                :class="[
                  'flex line-body mx-2 line-min',
                  isDone(appList[index], assign.userId) ? '' : 'no-done',
                ]">
                <div class="score-body score-name">{{assign.name}}</div>
                <div
                  v-for="(item, itemIndex) in viewScoreItemList" :key="`item_${itemIndex}`"
                  class="score-body score-item">
                  <!-- 点数表示・順位・チェック表示 -->
                  {{evalItem(appList[index], assign.userId, item.id, item.type)}}
                </div>
                <div
                  v-for="(item, itemIndex) in items[constEval.ADJUSTMENT]" :key="`item_adjustment_${itemIndex}`"
                  :class="[
                    'score-body score-item',
                    evalItem(appList[index], assign.userId, item.id, item.type) === '-' ? '' : 'text-red',
                    ]">
                  <!-- 点数表示・順位・チェック表示 -->
                  {{evalItem(appList[index], assign.userId, item.id, item.type)}}
                </div>
                <!-- 点数表示ありの時、最終列は合計点表示 -->
                <div
                  v-if="items[constEval.SCORE] && listIndex === viewScoreItemLists.length-1"
                  class="score-body score-item">
                  {{sumScores[index][assign.userId]}}
                </div>
              </div>
            </div>

            <!-- コメント -->
            <div
              v-if="viewCommentItemList.length > 0">
              <!-- 一段目 -->
              <div class="flex line-head mx-2">
                <div class="score-head score-name">
                  審査委員氏名
                </div>
                <div
                  v-for="(item, itemIndex) in viewCommentItemList" :key="`item_${itemIndex}`"
                  class="comment-head comment-item">
                  {{item.name}}
                </div>
              </div>
              <!-- 二段目以降は審査委員でループ -->
              <div
                v-for="(assign, assignIndex) in assigns[appList[index].bunri]" :key="`assign_${assignIndex}`"
                :class="[
                  'flex line-body mx-2 line-min',
                  isDone(appList[index], assign.userId) ? 'is-done' : '',
                ]">
                <div class="score-body score-name">{{assign.name}}</div>
                <div
                  v-for="(item, itemIndex) in viewCommentItemList" :key="`item_${itemIndex}`"
                  class="comment-body comment-item">
                  {{evalComment(appList[index], assign.userId, item.id)}}
                </div>
              </div>
            </div>

          </div>
          <!-- 点数審査ここまで -->

        </div>
      </div>

      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
          class="btn btn-lg bold"
          to="/cms/top">トップに戻る</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportCsv"
          @click="exportCsv()"
          >CSV出力</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportCsv"
          @click="exportExcel()"
          >Excel出力</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
// eslint-disable-next-line
import { cloneDeep } from 'lodash';
import api from '@/modules/api';
import download from '@/modules/download';
import CONST_USECASE from '@/constants/usecase';
import CONST_EVAL from '@/constants/evaluation';

export default {
  name: 'CmsApplicationsEvaluationView',
  data() {
    return {
      statusList: [
        { value: 0, text: '未' },
        { value: 1, text: '済' },
      ],
      yearList: [],
      rawApptypeData: {},
      viewUsecase: {},
      appList: [],
      // 点数・スコア入力欄
      inputs: {},
      // 合計点入力欄
      sumScoreInputs: {},
      // 順位のオプション達
      rankOptions: {},
      assigns: [],
      items: [],
      errMessages: {},
      perPage: 50,
      currentPage: 1,
      constUsecase: CONST_USECASE.USECASE,
      constEval: CONST_EVAL.EVALUATION_TYPE,
      search: {
        year: null,
        apptype: null,
        stageId: null,
        bunri: '',
      },
      viewFilter: false,
      sumScores: {},
      hasBunri: false,
    };
  },
  methods: {
    async initFetch() {
      const param = {
        year: this.search.year,
        apptype: this.search.apptype,
        stageId: this.search.stageId,
      };
      const promiseFuncs = [
        api.send('/api/cms/evaluation/list', param),
      ];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      if (!responses) {
        return;
      }
      this.yearList = responses[0].data.yearList;
      if (this.search.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.rawApptypeData = responses[0].data.apptypeData;
    },
    async fetchAppList() {
      this.appList = [];
      this.items = [];
      this.sumScores = [];
      this.viewUsecase = {};
      if (!this.hasSelect) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        apptype: this.search.apptype,
        stageId: this.search.stageId,
        bunri: this.search.bunri,
      };
      const response = await api.send('/api/cms/evaluation/view', param)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.appList = response.data.appList;
      this.items = response.data.items;
      this.viewUsecase = response.data.viewUsecase;
      this.assigns = response.data.assigns;
      this.calcSumScore();
    },
    setBunri(value) {
      const targetData = this.rawApptypeData[value];
      this.hasBunri = false;
      if (typeof targetData !== 'undefined') {
        this.hasBunri = targetData.hasBunri;
      }
    },
    setFilter(key, value) {
      this.$set(this.search, key, value);
    },
    // 助成プログラムの検索条件変更時、審査段階のフィルタリングを初期化する
    resetFilter(apptype, stage, bunri) {
      // リセット
      if (apptype) {
        this.$set(this.search, 'apptype', null);
        this.hasBunri = false;
      }
      if (stage) {
        this.$set(this.search, 'stageId', null);
      }
      if (bunri) {
        this.$set(this.search, 'bunri', '');
      }
      this.appList = [];
      this.items = [];
      this.viewUsecase = [];
    },
    calcSumScore() {
      if (!this.items[this.constEval.SCORE]) {
        return;
      }
      Object.keys(this.appList).forEach((appId) => {
        // 申請ごとにループ
        const app = this.appList[appId];
        // 平均点計算用の合計
        let avgSum = 0;
        // 先に事務局調整があれば取得する。ない場合は空配列、ある場合はオブジェクト。
        let sumAdjusts = 0;
        Object.keys(app.adjusts).forEach((adjustItemId) => {
          sumAdjusts += app.adjusts[adjustItemId].score;
        });
        this.sumScores[appId] = {};
        // アサインがない場合は
        if (!this.assigns[app.bunri]) {
          return;
        }
        // 平均点計算時の人数初期値
        let doneNumber = 0;
        // この申請にアサインされている人でループ
        this.assigns[app.bunri].forEach((marker) => {
          // 合計点\初期値
          this.sumScores[appId][marker.userId] = 0;
          if (!app.evals[marker.userId] || !app.evals[marker.userId].is_done || this.isParticipants(app.evals[marker.userId].items)) {
            // 完了でなければ合計点計算を行わない
            return;
          }
          // 完了なら、平均点計算用の人数に加算する。
          doneNumber += 1;
          // 点数評価項目でループ
          this.items[this.constEval.SCORE].forEach((scoreItem) => {
            const itemId = scoreItem.id;
            if (typeof app.evals[marker.userId].items[itemId] !== 'undefined') {
              this.sumScores[appId][marker.userId] += app.evals[marker.userId].items[itemId].evaluation;
            }
          });
          // 調整点数項目でループ
          if (typeof this.items[this.constEval.ADJUSTMENT] !== 'undefined') {
            this.items[this.constEval.ADJUSTMENT].forEach((scoreItem) => {
              const itemId = scoreItem.id;
              if (typeof app.evals[marker.userId].items[itemId] !== 'undefined') {
                this.sumScores[appId][marker.userId] += app.evals[marker.userId].items[itemId].evaluation;
              }
            });
          }
          // 最後に事務局調整を足して個人の合計点の計算終了
          this.sumScores[appId][marker.userId] += sumAdjusts;
          avgSum += this.sumScores[appId][marker.userId];
        });
        // この申請の審査員の平均点計算
        this.sumScores[appId].avg = 0;
        if (this.assigns[app.bunri].length !== 0 && doneNumber > 0) {
          this.sumScores[appId].avg = Math.round(avgSum / doneNumber * 10) / 10;
        }
      });
    },
    async exportCsv() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      // n次のnを取得
      const stageData = this.stageList.find(data => data.value === this.search.stageId);
      const params = {
        apptype: this.search.apptype,
        stage: stageData.no,
        bunri: this.search.bunri,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/export/all/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const apptype = this.apptypeList.find(data => data.value === this.search.apptype);
      const fileName = `${ymd}_${apptype.text}_${stageData.no}次.csv`;
      download.csvUtf(response.data, fileName);
    },
    async exportExcel() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      // n次のnを取得
      const stageData = this.stageList.find(data => data.value === this.search.stageId);
      const params = {
        apptype: this.search.apptype,
        stage: stageData.no,
        backStage: this.getBackStage,
        bunri: this.search.bunri,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/export/all/excel', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const apptype = this.apptypeList.find(data => data.value === this.search.apptype);
      const fileName = `${ymd}_${apptype.text}_${stageData.no}次.xlsx`;
      download.blob(response.data, fileName);
    },
    isParticipants(items) {
      let result = [];
      Object.keys(this.items).forEach((key) => {
        if (this.items[key] && this.items[key][0].type === 4) {
          result = this.items[key];
        }
      });
      let isParticipants = false;
      if (Object.keys(result).length > 0) {
        Object.keys(items).forEach((key) => {
          if (items[key].evaluation_item_id === result[0].id && items[key].evaluation === 1) {
            isParticipants = true;
          }
        });
      }
      return isParticipants;
    },
  },
  computed: {
    isShowApplyOrg() {
      const selectedAppData = this.rawApptypeData[this.search.apptype];
      const applyOrgTargetList = ['社会的・文化的諸活動助成', '国際会議開催助成', '目的型諸活動助成'];
      return applyOrgTargetList.includes(selectedAppData.name);
    },
    bunriOption() {
      if (!this.hasBunri) {
        return [];
      }
      return ['文', '理'];
    },
    defaultStageText() {
      if (this.stageList.length <= 0 && !this.search.apptype) {
        return '助成プログラムを選択してください';
      }
      if (this.stageList.length <= 0 && this.search.apptype) {
        return '審査段階が登録されていません';
      }
      return '-- 未選択 --';
    },
    apptypeList() {
      const apptypeList = [];
      Object.keys(this.rawApptypeData).forEach((key) => {
        if (this.rawApptypeData[key].year === Number(this.search.year)) {
          const param = {
            value: this.rawApptypeData[key].id,
            text: this.rawApptypeData[key].name,
          };
          apptypeList.push(param);
        }
      });
      return apptypeList;
    },
    stageList() {
      const apptypeId = this.search.apptype;
      if (apptypeId === null || !this.rawApptypeData[apptypeId]) {
        return [];
      }
      return this.rawApptypeData[apptypeId].evaluation_stages.map((stage) => {
        return {
          value: stage.id,
          text: `${stage.stage}次`,
          no: stage.stage,
        };
      });
    },
    hasSelect() {
      const okYear = this.search.year !== null;
      const okApptype = this.search.apptype !== null;
      const okStage = this.search.stageId !== null;
      return okYear && okApptype && okStage;
    },
    pageOptions() {
      return [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 25, text: 25 },
        { value: 50, text: 50 },
      ];
    },
    noDataMsg() {
      if (!this.hasSelect) {
        return '年度、助成プログラム、審査段階を選択してください。';
      }
      return '条件に一致するデータがありません。';
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    usecaseLabel() {
      return (constKey) => {
        const key = this.constUsecase[constKey];
        if (this.viewUsecase[key]) {
          return this.viewUsecase[key].label;
        }
        return null;
      };
    },
    filterdIndexList() {
      const indexList = [];
      Object.keys(this.appList).forEach((appId) => {
        indexList.push(appId);
      });
      return indexList;
    },
    totalRows() {
      return this.filterdIndexList.length;
    },
    vewFilterIndexList() {
      const from = (this.currentPage - 1) * this.perPage;
      const to = this.currentPage * this.perPage;
      return this.filterdIndexList.slice(from, to);
    },
    // 一列当たり、コメントを除き9個の配列を作成する
    viewScoreItemLists() {
      let index = 0;
      let count = 0;
      const itemLists = [[]];
      Object.keys(this.items).forEach((type) => {
        const intType = Number(type);
        if (intType === this.constEval.SCORE || intType === this.constEval.ZAIDAN
        || intType === this.constEval.RANK || intType === this.constEval.CHECK) {
          this.items[type].forEach((item) => {
            count += 1;
            itemLists[index].push(item);
            // 9個ちょうどの時は最後のindexは空配列になる。合計点を表示するために必要。
            if (count === 9) {
              index += 1;
              count = 0;
              if (!itemLists[index]) {
                itemLists[index] = [];
              }
            }
          });
        }
      });
      return itemLists;
    },
    viewCommentItemList() {
      return this.items[this.constEval.COMMENT] || [];
    },
    isDone() {
      return (app, userId) => {
        if (!userId || !app.evals[userId]) {
          return 0;
        }
        return app.evals[userId].is_done;
      };
    },
    evalItem() {
      return (app, userId, itemId, type) => {
        // itemIdが事務局調整のものであれば、そちらを参照する
        if (app.adjusts[itemId]) {
          return app.adjusts[itemId].score;
        }
        // なかったら空
        if (!app.evals[userId] || !app.evals[userId].items[itemId]) {
          return '';
        }
        // 点数の時
        if (type === this.constEval.SCORE || type === this.constEval.ADJUSTMENT) {
          return app.evals[userId].items[itemId].evaluation !== 0 ? app.evals[userId].items[itemId].evaluation : '-';
        }
        // ランクの場合
        if (type === this.constEval.RANK) {
          return `${app.evals[userId].items[itemId].evaluation}位`;
        }
        // チェックの場合
        return app.evals[userId].items[itemId].evaluation === 1 ? '有' : '無';
      };
    },
    evalComment() {
      return (app, userId, itemId) => {
        // なかったら空
        if (!app.evals[userId] || !app.evals[userId].items[itemId]) {
          return '';
        }
        return app.evals[userId].items[itemId].comment;
      };
    },
    canExportCsv() {
      return this.search.apptype && this.search.stageId && this.totalRows > 0;
    },
    name() {
      return (nameData) => {
        let name = nameData[0].field_answer_text;
        if (nameData[1]) {
          name += ` ${nameData[1].field_answer_text}`;
        }
        return name;
      };
    },
    nameClass() {
      if (this.viewUsecase[this.constUsecase.MONEY] && this.viewUsecase[this.constUsecase.TERM]) {
        return 'view-middle mr-2px inlineBlock';
      // eslint-disable-next-line
      } else if (this.viewUsecase[this.constUsecase.MONEY] || this.viewUsecase[this.constUsecase.TERM]) {
        return 'view-middle-long mr-2px inlineBlock';
      }
      return 'view-long inlineBlock';
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    await this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .eval-view-check>.custom-control-label::after, .eval-view-check>.custom-control-label::before {
    width: 20px !important;
    height: 20px !important;
  }
</style>

<style scoped>
  .search-area input, .search-area select, .filter-area input, .filter-area select {
    height: 50px;
  }

  .search-area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .search-space {
    margin-right: 15px;
  }
  .search-middle {
    width: 300px;
  }
  .search-middle-long {
    width: 340px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }
  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .mr-2px {
    margin-right: 2px;
  }

  .app-area {
    background-color: #FFF;
  }
  .left-area {
    width: 539px;
  }
  .right-area {
    width: 539px;
    margin-left: 2px;
  }
  .field-name {
    background-color: #DDD;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    font-weight: bold;
  }
  .field-value {
    padding: 10px 0;
    min-height: 45px;
    display: -webkit-flex;
    display: flex;
  }
  .flex-tate {
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
  }
  .flex-yoko {
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
  }

  .view-long {
    width: 949px;
  }
  .view-middle-long {
    width: 537px;
  }
  .view-middle {
    width: 408px;
  }
  .view-middle-short {
    width: 286px;
  }
  .view-short {
    width: 129px;
  }
  .view-minimum {
    width: 63px;
  }

  .score-wrap {
    width: 160px;
    margin-right: 10px;
    display: inline-block;
  }
  .score-wrap>select, .score-wrap>input {
    height: 50px;
  }
  .score-name {
    font-size: 12px;
  }
  .comment-wrap {
    width: 500px;
  }

  .filter-area {
    background: #FFF;
    padding: 10px;
    margin-bottom: 30px;
  }
  .filter-line {
    border-color:#293CB0 !important;
    border-width: 1px;
  }
  .filter-text {
    color: #293CB0;
    margin-right: 10px;
    font-weight: bold;
  }
  .filter-btn {
    margin-bottom: 3px;
  }
  .filter-btn:hover {
    cursor: pointer;
  }

  .score-head {
    background-color: #6E6E6E;
    color: #FFF;
    margin-right: 1px;
    min-height: 60px;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }
  .score-name {
    width: 150px;
  }
  .score-item {
    width: 100px;
  }
  .score-body {
    margin-right: 1px;
    height: 50px;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
    padding: 5px;
    font-size: 16px;
  }

  .comment-head {
    background-color: #6E6E6E;
    color: #FFF;
    margin-right: 1px;
    height: 60px;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    width: 910px;
    text-align: center;
  }
  .comment-body {
    margin-right: 1px;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    padding: 5px;
    font-size: 14px;
    width: 910px;
    white-space:pre-wrap;
    word-wrap:break-word;
  }

  .line-min {
    width: min-content;
  }

  .line-body {
    border-bottom: solid 1px #bbb;
  }

  .is-done {
    background-color: #eee;
  }
  .text-red {
    color: #dc3545;
  }
</style>
